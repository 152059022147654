import ArticlesFeed from '../Components/ArticlesFeed';
import Search from '../Components/Search';

const Home = () => {
  return (
    <>
        <Search/>
        <ArticlesFeed/>
    </>
  )
}

export default Home