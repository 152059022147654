import { useEffect, useState } from 'react'
import ArticleItem from './ArticleItem'
import Loader from './Loader'

const ArticlesFeed = () => {
    const [data,setData] = useState([])
    const [loading,setLoading] = useState(true)

    useEffect(()=>{
      fetchResult()
    },[])
  
    const fetchResult = async () => {
      let result = await fetch(`https://dummyjson.com/products`)
      result = await result.json()
        setData(result.products)
        setLoading(false)
    }

  return (
    <div className="container mt-5">

        <h1>Articles</h1>

        {
          loading
          ? <Loader/>
          
          : <div className="row">
            {
              data.map(item=>
                <ArticleItem key={item.id} id={item.id} title={item.title} description={item.description} thumbnail={item.thumbnail}/>
                )
            }
          </div>
        }
    </div>
  )
}

export default ArticlesFeed