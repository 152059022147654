import {useEffect, useState } from "react"

const Search = () => {

    const [searchQuery,setSearchQuery] = useState('')
    const [searhResult, setSearhResult] = useState([])

    const searchHandle = () => {
        console.log('search', searchQuery)
    }

    useEffect(()=>{
        if(searchQuery){
            fetchResult()
        }
    },[searchQuery])

    const fetchResult = async () => {
        let result = await fetch(`https://dummyjson.com/products/search?q=${searchQuery}`)
        result = await result.json()
        setSearhResult(result.products)
    }
    

  return (
    <>
        <div className="search-container">
            <div className="search-container-inner">
                <h3 className='text-center'>Search Products</h3>
                <input type="text" value={searchQuery} onChange={e=>setSearchQuery(e.target.value)} placeholder='Search...'/>
                { searchQuery &&
                    <ul className='dropdown'>
                    {
                        searhResult
                        .filter(result => {
                            let query = searchQuery.toLocaleLowerCase()
                            let resultTitle = result.title.toLocaleLowerCase()
                            return resultTitle.includes(query) && resultTitle !== query
                        })
                        .map(result =>
                            <li key={result.id} onClick={()=> setSearchQuery(result.title)}> {result.title}</li>
                        )
                        
                    }
                    </ul>
                }
            </div>
        </div>
    </>
  )
}

export default Search